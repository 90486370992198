<template>
  <div>
    <slot name="action">
      <a href="#" class="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Ajouter</a>
      <!-- <b-button variant="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Créer</b-button> -->
    </slot>
    <b-modal
      id="modal-footer-sm"
      size="lg"
      ref="modal"
      hide-footer
      :title="title"
      modal-ok="Valider"
      modal-cancel="Annuler"
    >
      <template #modal-header>
        <slot name="formTitle">
          <h5 class="modal-title" id="staticBackdropLabel">Référentiel</h5>
        </slot>
        <button
          type="button"
          class="style_btn btn-close"
          data-bs-dismiss="modal"
          @click.prevent="cancel"
          aria-label="Close"
        ></button>
      </template>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <slot
            name="formContent"
            :handleInput="handleInput"
            :editableItem="editableItem"
          >
            <div>Aucun contenu pour le formulaire</div>
          </slot>
          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableItem: {},
    };
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    setActiveItem(item) {
      this.editableItem = item;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>
